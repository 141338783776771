import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const IconGitHub = ({ colour, size }) => {
  const theme = useTheme();
  const fill = colour || theme.colors.grey6;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 0.637878C3.83163 0.637878 0.451508 4.01725 0.451508 8.18638C0.451508 11.5216 2.61438 14.351 5.61363 15.3493C5.99076 15.4193 6.12926 15.1855 6.12926 14.9861C6.12926 14.8061 6.12226 14.2115 6.11901 13.5808C4.01901 14.0374 3.57588 12.6901 3.57588 12.6901C3.23251 11.8176 2.73776 11.5856 2.73776 11.5856C2.05276 11.1171 2.78938 11.1269 2.78938 11.1269C3.54726 11.18 3.94651 11.9048 3.94651 11.9048C4.61976 13.0585 5.71238 12.725 6.14313 12.5323C6.21088 12.0444 6.40651 11.7113 6.62238 11.5228C4.94576 11.3321 3.18313 10.6848 3.18313 7.79238C3.18313 6.96826 3.47813 6.29488 3.96101 5.76613C3.88251 5.57613 3.62413 4.80838 4.03401 3.76863C4.03401 3.76863 4.66776 3.56575 6.11026 4.54238C6.71251 4.375 7.35838 4.29088 8.00001 4.288C8.64126 4.29088 9.28751 4.37475 9.89088 4.54213C11.3316 3.5655 11.9646 3.76838 11.9646 3.76838C12.3755 4.808 12.1171 5.57588 12.0388 5.76588C12.5228 6.29463 12.8156 6.96801 12.8156 7.79213C12.8156 10.6914 11.0498 11.3296 9.36888 11.5166C9.63976 11.7509 9.88101 12.2104 9.88101 12.9145C9.88101 13.9245 9.87226 14.7374 9.87226 14.986C9.87226 15.1869 10.0083 15.4223 10.3908 15.3481C13.3883 14.3489 15.5486 11.5204 15.5486 8.18638C15.5485 4.0175 12.1688 0.638003 8.00001 0.638003V0.637878Z"
        fill={fill}
      />
      <path
        d="M3.31051 11.4757C3.29388 11.5132 3.23488 11.5245 3.18113 11.4989C3.12613 11.4744 3.09551 11.4232 3.11326 11.3856C3.12951 11.3469 3.18863 11.3362 3.24326 11.3621C3.29826 11.3867 3.32951 11.4384 3.31038 11.4759L3.31051 11.4757ZM3.61626 11.8169C3.58038 11.8502 3.51001 11.8347 3.46226 11.7819C3.41276 11.7294 3.40351 11.659 3.44013 11.6251C3.47738 11.5919 3.54563 11.6076 3.59513 11.6601C3.64438 11.7134 3.65413 11.7831 3.61638 11.817L3.61626 11.8169ZM3.91401 12.2515C3.86776 12.2837 3.79201 12.2536 3.74526 12.1865C3.69901 12.1192 3.69901 12.0386 3.74651 12.0065C3.79313 11.9742 3.86776 12.0034 3.91526 12.0699C3.96126 12.138 3.96126 12.2186 3.91401 12.2514V12.2515ZM4.32163 12.6716C4.28038 12.7172 4.19213 12.705 4.12763 12.6429C4.06176 12.582 4.04338 12.4954 4.08476 12.4499C4.12676 12.4041 4.21538 12.4169 4.28026 12.4786C4.34613 12.5394 4.36601 12.6261 4.32188 12.6715L4.32163 12.6716ZM4.88413 12.9155C4.86576 12.9746 4.78101 13.0015 4.69538 12.9762C4.61001 12.9504 4.55413 12.8812 4.57163 12.8215C4.58913 12.7619 4.67451 12.734 4.76063 12.7609C4.84601 12.7866 4.90188 12.8554 4.88413 12.9155ZM5.50201 12.9606C5.50413 13.0229 5.43163 13.0744 5.34201 13.0756C5.25163 13.0777 5.17851 13.0272 5.17763 12.966C5.17763 12.9031 5.24863 12.8522 5.33888 12.8505C5.42851 12.8489 5.50213 12.8989 5.50213 12.9605L5.50201 12.9606ZM6.07676 12.8629C6.08751 12.9235 6.02513 12.9859 5.93601 13.0025C5.84851 13.0187 5.76726 12.981 5.75601 12.9209C5.74526 12.8586 5.80876 12.7962 5.89626 12.7801C5.98551 12.7647 6.06551 12.8014 6.07676 12.863V12.8629Z"
        fill={fill}
      />
    </svg>
  );
};

IconGitHub.defaultProps = {
  colour: null,
  size: 16,
};

IconGitHub.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.number,
};

export default IconGitHub;
