import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const IconLinkedIn = ({ colour, size }) => {
  const theme = useTheme();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 0.374997H1.5C1.20504 0.371995 0.920934 0.486127 0.710024 0.69235C0.499114 0.898574 0.378626 1.18004 0.375 1.475V14.5275C0.379282 14.822 0.500058 15.1028 0.710896 15.3085C0.921735 15.5142 1.20547 15.628 1.5 15.625H14.5C14.795 15.6273 15.0789 15.5128 15.2897 15.3065C15.5005 15.1001 15.621 14.8187 15.625 14.5238V1.47125C15.6197 1.17716 15.4986 0.89703 15.288 0.691754C15.0773 0.486477 14.7941 0.372644 14.5 0.374997ZM4.89625 13.375H2.6325V6.09125H4.89625V13.375ZM3.77125 5.09875C3.51161 5.099 3.25773 5.02223 3.04172 4.87816C2.82572 4.73409 2.6573 4.52919 2.55777 4.28939C2.45824 4.04958 2.43207 3.78564 2.48257 3.53096C2.53307 3.27628 2.65797 3.04229 2.84148 2.85861C3.02499 2.67493 3.25885 2.5498 3.51348 2.49906C3.76812 2.44831 4.03208 2.47423 4.27198 2.57354C4.51188 2.67284 4.71694 2.84107 4.86122 3.05693C5.00549 3.2728 5.0825 3.52661 5.0825 3.78625C5.08217 3.95882 5.04782 4.12963 4.9814 4.28891C4.91498 4.44819 4.8178 4.5928 4.69543 4.71448C4.57305 4.83616 4.42788 4.93251 4.26823 4.99802C4.10858 5.06353 3.93757 5.09691 3.765 5.09625L3.77125 5.09875ZM13.375 13.375H11.1112V9.83125C11.1112 8.9875 11.0963 7.90125 9.935 7.90125C8.77375 7.90125 8.57625 8.82125 8.57625 9.77625V13.375H6.31625V6.09125H8.48625V7.09125H8.51625C8.81875 6.51875 9.55625 5.915 10.6575 5.915C12.95 5.91 13.375 7.41875 13.375 9.375V13.375Z"
        fill={colour || theme.colors.grey6}
      />
    </svg>
  );
};

IconLinkedIn.defaultProps = {
  colour: null,
  size: 16,
};

IconLinkedIn.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.number,
};

export default IconLinkedIn;
