import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const IconTwitter = ({ colour, size }) => {
  const theme = useTheme();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03168 14.0046C11.0697 14.0046 14.3714 9.00208 14.3714 4.66494C14.3714 4.52299 14.3714 4.38104 14.3625 4.24035C15.0049 3.7755 15.5594 3.19998 16 2.54073C15.4012 2.80634 14.7659 2.98065 14.1153 3.05784C14.8003 2.64721 15.3129 2.0018 15.5577 1.24162C14.9132 1.62419 14.2081 1.89381 13.4727 2.03883C12.9779 1.51263 12.3234 1.16421 11.6105 1.04747C10.8977 0.93074 10.1662 1.05221 9.52938 1.39308C8.89252 1.73394 8.38577 2.27521 8.08753 2.93311C7.7893 3.59102 7.71621 4.32887 7.87959 5.0325C6.57446 4.96711 5.29767 4.62798 4.13212 4.03712C2.96656 3.44626 1.93829 2.61689 1.11407 1.60284C0.693868 2.32529 0.564818 3.18073 0.753195 3.99499C0.941572 4.80925 1.43321 5.5211 2.12801 5.9856C1.60921 5.96807 1.1021 5.82684 0.648922 5.57369C0.648922 5.58763 0.648922 5.60157 0.648922 5.61551C0.648832 6.37255 0.910303 7.10637 1.38908 7.69278C1.86786 8.27918 2.53455 8.68216 3.2763 8.83351C2.79277 8.96523 2.28547 8.9843 1.79341 8.88928C2.00291 9.54191 2.41122 10.1127 2.96119 10.5218C3.51116 10.9308 4.17528 11.1577 4.86058 11.1706C3.69708 12.0818 2.2611 12.5755 0.78327 12.5724C0.521511 12.5726 0.259971 12.5573 0 12.5268C1.50098 13.4909 3.24774 14.0026 5.03168 14.0008"
        fill={colour || theme.colors.grey5}
      />
    </svg>
  );
};

IconTwitter.defaultProps = {
  colour: null,
  size: 16,
};

IconTwitter.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.number,
};

export default IconTwitter;
